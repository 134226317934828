import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
    GET_APPLICATION_HISTORY
} from '../constants/ApplicationHistory';
import {
    getApplicationHistorySuccess
} from "../actions/ApplicationHistory";


import services from 'services/PrivateServices';




export function* GetApplicationHistory() {

    yield takeEvery(GET_APPLICATION_HISTORY, function* ({ filter }) {

        try {

            const data = yield call(services.getHistoryApplication, filter);

            const fomattedData = data

            yield put(getApplicationHistorySuccess(fomattedData))

        } catch (err) {



        }

    });

}


export default function* rootSaga() {
    yield all([
        fork(GetApplicationHistory),

    ]);
}
