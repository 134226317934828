import { useState, useEffect,useMemo} from "react";
import { HeaderFront } from "components/layout-components/HeaderFront"
import { FooterFront } from "components/layout-components/FooterFront";
import { Col, Row, Input, Select, Button, message } from 'antd';
import { ReactComponent as Civility } from 'assets/img/landing/civility.svg';
import { ReactComponent as SelectArrow } from 'assets/img/landing/select-arrow.svg';
import { ReactComponent as UserApplication } from 'assets/img/landing/user-application.svg';
import { ReactComponent as EmailApplication } from 'assets/img/landing/email-application.svg';
import { ReactComponent as PhoneApplication } from 'assets/img/landing/phone.svg';
import { ReactComponent as TN } from 'assets/img/landing/TN.svg';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import clsx from 'clsx';
import { FieldNumberOutlined } from '@ant-design/icons';
import publicServices from 'services/PublicService';
import {getCategoryContact } from "redux/actions/Governoments";
import { useDispatch, useSelector } from 'react-redux';

const Contact = () => {
  const { Option } = Select
  const { TextArea } = Input;
  const { category_contact } = useSelector(state => state.govs)
  const dispatch = useDispatch()

  useEffect(() => {
    !category_contact && dispatch(getCategoryContact())
  }, [])

  //Form Schema
  const FormSchema = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    category: "",
    message: "",
  }
  const FormSchemaValidation = {
    first_name: true,
    last_name: true,
    email: true,
    phone_number: true,
    category: true,
    message: true,
  }

  const [formFields, setFormFields] = useState(FormSchema)
  const [validation, setValidation] = useState(FormSchemaValidation)
  const [isReclamation, setIsReclamation] = useState(false)
  const [fieldsReclamation, setFieldsReclamation] = useState({})
  const [loader, setloader] = useState(false)

  // 👇️ scroll to top on page load
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  // Formated Phone
  const formattedPhoneNumber = (phone) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(phone, 'TN')
    return parsedPhoneNumber ? parsedPhoneNumber?.formatNational() : phone
  }
  // Init Caracters Fields
  const onlyCaractersKey = ["first_name", "last_name"];
  const onlyCharachters = value => {
    const result = value.replace(/[^a-zA-z :space]/g, '');
    return result
  };

  // Callback Input Change
  const handleChangeInput = (e) => {
    const { name, value } = e.target
    if (name == "phone_number") {
      if (value.toString() != "" && (isNaN(value.replaceAll(" ", "")) || value.toString().indexOf(",") != -1 || value.toString().indexOf(".") != -1 || value.replaceAll(" ", "").length > 8)) {
        return
      }
    }
    if (!validation[name]) {
      value?.toString().trim().length && setValidation({
        ...validation,
        [name]: true
      })
      setTimeout(() => {
        const element = document.getElementById(name).classList
        element.add("label-top")
        name === "phone_number" && element.add('input-focused')
      },);
    }

    return setFormFields({
      ...formFields,
      [name]: onlyCaractersKey.find(el => el === name) ? onlyCharachters(value) : value
    })
  }


// Callback Input Change Category
const handleChangeCategory = (value) => {
  const name = 'category';
  if(value == "Réclamation"){
    setFormFields((prev) => { return {...prev,category_reclamation : '',code_client:''}})
    setValidation((prev) => { return {...prev,message:true,category_reclamation : true,code_client:true}})
    const groupedKeys = category_contact?.data?.reduce((group, item) => {
      if (!group[item?.category?.id]) {
        group[item.category.id] = [];
      }
      group[item?.category?.id].push(item);
      return group;
    }, {});
    setIsReclamation(true);
    setFieldsReclamation(groupedKeys);
    return setFormFields((prev) => { return {
      ...prev,
      [name]: value
    }})
  }else{
    setIsReclamation(false);
    setFieldsReclamation({});
    const old_fields = formFields;  
    delete old_fields.sub_category_reclamation;
    delete old_fields.category_reclamation;
    delete old_fields.option_category;
    delete old_fields.option_sub_category;
    delete old_fields.code_client;
    return setFormFields({
      ...old_fields,
      [name]: value
    })
  }
}

  // Before input phone
  const selectPhoneCode = (
    <div className='d-flex align-items-center'>
      <div className='mrc-14' >
        <PhoneApplication />
      </div>
      <div className='select-phone d-flex flex-column'>
        <div className='phone-text mb-1'>
          Télèphone
        </div>
        <Select defaultValue="TN" className='phone-ant-select' suffixIcon={<SelectArrow />}>
          <Option value={"TN"}>
            <div className='d-flex align-items-center flag-option'>
              <TN className='mr-1' /> <span className='text'>+216</span>
            </div>
          </Option>
        </Select>
      </div>
    </div>
  )
  const handleFocusInput = (id) => {
    const element = document.getElementById(id)
    !element.classList.contains('label-top') && element.classList.add("label-top")
    id === "phone_number" && element.classList.add("input-focused")
  }
  const handleBlurInput = (e, id) => {
    const value = formFields[id]
    const element = document.getElementById(id)
    value.toString().trim().length === 0 && element.classList.remove('label-top')
    id === "phone_number" && element.classList.remove("input-focused")
  }

  // Validate Form
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const phoneValidation = (phone) => phone.trim().length > 0 && parsePhoneNumberFromString(phone, "TN").isValid()
  const emailValidation = (email) => email.trim().length > 0 && emailRegex.test(email.trim())
  const validationSchema = () => {
    let newvalidtionSchema = {}
    if(isReclamation){
      Object.keys(formFields).forEach(el => newvalidtionSchema = {
        ...newvalidtionSchema,
        [el]: el === "email" ? emailValidation(formFields[el]) : el === "phone_number" ? phoneValidation(formFields[el]) : formFields[el].toString().length > 0
      })
    }else{
      Object.keys(FormSchema).forEach(el => newvalidtionSchema = {
        ...newvalidtionSchema,
        [el]: el === "email" ? emailValidation(formFields[el]) : el === "phone_number" ? phoneValidation(formFields[el]) : formFields[el].toString().length > 0
      })
    }
    if(isReclamation){
      delete newvalidtionSchema.message
    }
    setValidation(newvalidtionSchema)
    if (!newvalidtionSchema.email && formFields.email.trim().length) {
      setTimeout(() => {
        document.getElementById("email").classList.add('label-top')
      },);
    }
    if (!newvalidtionSchema.phone_number && formFields.phone_number.trim().length) {
      setTimeout(() => {
        document.getElementById("phone_number").classList.add('label-top')
      },);
    }

    return Object.values(newvalidtionSchema).every(el => el)
  }

  // Handle Submit Click
  const onSubmit = async () => {
    setloader(true);
    if (!validationSchema()) {
      message.error("Veuillez vérifier vos données saisies")
      setloader(false);
      return
    }
    //Send Data to Api
    try {
      await publicServices.addContact(formFields);
      message.success('Votre message a été envoyé avec succès');
      setFormFields(FormSchema);
      setIsReclamation(false)
      Object.keys(FormSchema).forEach(item => document.getElementById(item).classList.remove('label-top'));
      setloader(false);
    } catch (e) {
      setloader(false);
    }

  }

//category Reclamation
const visibleFieldsCategoryReclamation = useMemo(() => {
    if(fieldsReclamation && isReclamation){
    return (<Col className='mbc-20' xs={24}>
      <div className={clsx('input-contact custom-input-wrapper', { "error-input": !validation.code_client })} id='code_client'>
                <Input value={formFields.code_client} name="code_client" onChange={handleChangeInput} prefix={<FieldNumberOutlined />} className='custom-input' onFocus={() => handleFocusInput("code_client")} onBlur={(e) => handleBlurInput(e, "code_client")} />
                <div className='floating-label'>
                  Code client
                </div>
              </div>
              <div className='error-message'>
                {
                  !validation.code_client &&  "Champ requis" 
                }
      </div>
      <div className={`custom-input-wrapper ${!validation.category_reclamation ? "error-input" : ""}`} id="category_reclamation">
        <Select value={formFields.category_reclamation} onChange={(e) => {
                  if (!validation.category_reclamation) {
                    setValidation({
                      ...validation,
                      category_reclamation: true
                    })
                    setTimeout(() => {
                      document.getElementById("category_reclamation").classList.add('label-top');
                    });
                  }
                  setFormFields((prev) => { return {...prev,category_reclamation : e,sub_category_reclamation : ''}})
                  setValidation((prev) => { return {...prev,sub_category_reclamation : true}})
                }} onFocus={() => handleFocusInput('category_reclamation')} onBlur={(e) => handleBlurInput(e, "category_reclamation")} className='w-100 custom-select' suffixIcon={<SelectArrow />}>
          {
          Object.values(fieldsReclamation)?.map((item,index) => <Option key={index} value={item[0]?.category?.id}> {item[0]?.category?.title} </Option>)
          }
        </Select>
        <div className='prefix-select-icon'>
          <Civility />
        </div>
        <div className='floating-label'>
          Catégorie
        </div>
        <div className='error-message'>
            {
              !validation.category_reclamation && "Champ requis"
            }
        </div>
      </div>
    </Col>);
    }else{
      return '';
    }
},[isReclamation,validation,formFields]);

//Sub Category Reclemation
const visibleFieldsSubCategoryReclamation = useMemo(() => {

    if(fieldsReclamation && isReclamation && formFields.category_reclamation){
    const options = [];
    Object.values(fieldsReclamation)?.map(elm => elm.map(item => item.category.id == formFields.category_reclamation && options.push(item)))
    if((options && options.length == 0) || !options){
        const old_validation = validation;  
        delete old_validation.sub_category_reclamation;
        const old_fields = formFields;  
        delete old_fields.sub_category_reclamation;
        setFormFields(old_fields)
        setValidation(old_validation)
    }
    return options && options.length > 0 &&  (<Col className='mbc-20' xs={24}>
      <div className={`custom-input-wrapper ${!validation.sub_category_reclamation ? "error-input" : ""}`} id="sub_category_reclamation">
        <Select value={formFields.sub_category_reclamation} onChange={(e) => {
                  if (!validation.sub_category_reclamation) {
                    setValidation({
                      ...validation,
                      sub_category_reclamation: true
                    })
                    setTimeout(() => {
                      document.getElementById("sub_category_reclamation").classList.add('label-top');
                    });
                  }
                  setFormFields((prev) => { return {...prev,sub_category_reclamation : e , option_category: ''}})
                  setValidation((prev) => { return {...prev,option_category : true}})
                }} onFocus={() => handleFocusInput('sub_category_reclamation')} onBlur={(e) => handleBlurInput(e, "sub_category_reclamation")} className='w-100 custom-select' suffixIcon={<SelectArrow />}>
          {
          options.map((item,index) => <Option key={index} value={item.id}> {item.name} </Option>)
          }
        </Select>
        <div className='prefix-select-icon'>
          <Civility />
        </div>
        <div className='floating-label'>
          Sous-catégorie 1
        </div>
        <div className='error-message'>
            {
              !validation.sub_category_reclamation && "Champ requis"
            }
        </div>
      </div>
    </Col>) 
    }else{
      return '';
    }
},[isReclamation,validation,formFields]);


//Option Category Reclemation
const visibleFieldsOptionsCategoryReclamation = useMemo(() => {

  if(fieldsReclamation && isReclamation && formFields.category_reclamation && formFields.sub_category_reclamation){
  const options_sub = [];
  Object.values(fieldsReclamation)?.map(elm => elm?.map(item => item.id == formFields?.sub_category_reclamation && item?.options_sub_category?.map(e => options_sub.push(e.title))))
  if((options_sub && options_sub.length == 0) || !options_sub){
    const old_validation = validation;  
    delete old_validation.option_category;
    const old_fields = formFields;  
    delete old_fields.option_category;
    setFormFields(old_fields)
    setValidation(old_validation)
  }
  return options_sub && options_sub.length > 0 && (<Col className='mbc-20' xs={24}>
    <div className={`custom-input-wrapper ${!validation.option_category ? "error-input" : ""}`} id="option_category">
      <Select value={formFields.option_category} onChange={(e) => {
                if (!validation.option_category) {
                  setValidation({
                    ...validation,
                    option_category: true
                  })
                  setTimeout(() => {
                    document.getElementById("option_category").classList.add('label-top');
                  });
                }
                setFormFields((prev) => { return {...prev,option_category : e , option_sub_category: ''}})
                setValidation((prev) => { return {...prev,option_sub_category : true}})
              }} onFocus={() => handleFocusInput('option_category')} onBlur={(e) => handleBlurInput(e, "option_category")} className='w-100 custom-select' suffixIcon={<SelectArrow />}>
        {
        options_sub?.map((item,index) => <Option key={index} value={item}> {item} </Option>)
        }
      </Select>
      <div className='prefix-select-icon'>
        <Civility />
      </div>
      <div className='floating-label'>
        Sous-catégorie 2
      </div>
      <div className='error-message'>
          {
            !validation.option_category && "Champ requis"
          }
      </div>
    </div>
  </Col>);
  }else{
    return '';
  }
},[isReclamation,validation,formFields]);

//Option Sub Category Reclemation
const visibleFieldsOptionsSubCategoryReclamation = useMemo(() => {

  if(fieldsReclamation && isReclamation && formFields.category_reclamation &&  formFields.option_category){
  const options_sub = [];
  Object.values(fieldsReclamation)?.map(elm => elm?.map(item => item?.id == formFields?.sub_category_reclamation && item?.options_sub_sub_category?.map(e => options_sub.push(e?.title_option))))
  if((options_sub && options_sub.length == 0) || !options_sub){
    const old_validation = validation;  
    delete old_validation.option_sub_category;
    const old_fields = formFields;  
    delete old_fields.option_sub_category;
    setFormFields(old_fields)
    setValidation(old_validation)
  }
  return options_sub && options_sub.length > 0 && (<Col className='mbc-20' xs={24}>
    <div className={`custom-input-wrapper ${!validation.option_sub_category ? "error-input" : ""}`} id="option_sub_category">
      <Select value={formFields.option_sub_category} onChange={(e) => {
                if (!validation.option_sub_category) {
                  setValidation({
                    ...validation,
                    option_sub_category: true
                  })
                  setTimeout(() => {
                    document.getElementById("option_sub_category").classList.add('label-top');
                  });
                }
                setFormFields((prev) => { return {...prev,option_sub_category : e}})
                setValidation((prev) => { return {...prev,option_sub_category : true}})
              }} onFocus={() => handleFocusInput('option_sub_category')} onBlur={(e) => handleBlurInput(e, "option_sub_category")} className='w-100 custom-select' suffixIcon={<SelectArrow />}>
        {
        options_sub?.map((item,index) => <Option key={index} value={item}> {item} </Option>)
        }
      </Select>
      <div className='prefix-select-icon'>
        <Civility />
      </div>
      <div className='floating-label'>
        Sous-catégorie 3
      </div>
      <div className='error-message'>
          {
            !validation.option_sub_category && "Champ requis"
          }
      </div>
    </div>
  </Col>);
  }else{
    return '';
  }
},[isReclamation,validation,formFields]);

  return (
    <>
      <HeaderFront />
      <div className="container contact-page application-form-container">
        <h1 className="title-center">Contactez-nous</h1>
        <p className="subtitle">Si vous avez des questions, n'hésitez pas à nous contactez.</p>
        <div className='custom-form form-contact'>
          <Row gutter={10}>
          
            <Col md={8} xs={24} className={clsx('mobile-width-50', { "error-type": !validation.category })}>
              <div className={clsx('custom-radio pointer ', { "checked": formFields.category == "Information" },{ "error-input": !validation.category })} onClick={() => handleChangeCategory("Information")}>
                <div className='d-flex align-items-center '>
                  <span className='radio'> </span> <span className='text'>Information</span>
                </div>
              </div>
            </Col>
            <Col md={8} xs={24} className={clsx('mobile-width-50', { "error-type": !validation.category })}>
              <div className={clsx('custom-radio pointer ', { "checked": formFields.category == "Réclamation" },{ "error-input": !validation.category })} onClick={() => handleChangeCategory("Réclamation")}>
                <div className='d-flex align-items-center '>
                  <span className='radio'> </span>  <span className='text'>Réclamation</span>
                </div>
              </div>
            </Col>
            <Col md={8} xs={24} className={clsx('mobile-width-50', { "error-type": !validation.category })}>
              <div className={clsx('custom-radio pointer ', { "checked": formFields.category == "Question" },{ "error-input": !validation.category })} onClick={() => handleChangeCategory("Question")}>
                <div className='d-flex align-items-center '>
                  <span className='radio'> </span>  <span className='text'>Question</span>
                </div>
              </div>
            </Col>
            <Col md={24} >
              <div className='error-message'>
                  {
                    !validation.category && "Champ requis"
                  }
              </div>
            </Col>

            <Col className='mbc-20 mgt-20' md={12} xs={24}>
              <div className={clsx('input-contact custom-input-wrapper', { "error-input": !validation.last_name })} id='last_name'>
                <Input value={formFields.last_name} name="last_name" onChange={handleChangeInput} prefix={<UserApplication />} className='custom-input' onFocus={() => handleFocusInput("last_name")} onBlur={(e) => handleBlurInput(e, "last_name")} />
                <div className='floating-label'>
                  Nom
                </div>
              </div>
              <div className='error-message'>
                {
                  !validation.last_name && "Champ requis"
                }
              </div>
            </Col>
            <Col className='mbc-20' md={12} xs={24}>
              <div className={clsx('input-contact custom-input-wrapper', { "error-input": !validation.first_name })} id='first_name'>
                <Input value={formFields.first_name} name="first_name" onChange={handleChangeInput} prefix={<UserApplication />} className='custom-input' onFocus={() => handleFocusInput("first_name")} onBlur={(e) => handleBlurInput(e, "first_name")} />
                <div className='floating-label'>
                  Prénom
                </div>
              </div>
              <div className='error-message'>
                {
                  !validation.first_name && "Champ requis"
                }
              </div>
            </Col>
            <Col className='mbc-20' xs={24}>
              <div className={clsx('input-contact custom-input-wrapper', { "error-input": !validation.email })} id='email'>
                <Input value={formFields.email} name="email" onChange={handleChangeInput} prefix={<EmailApplication />} className='custom-input' onFocus={() => handleFocusInput("email")} onBlur={(e) => handleBlurInput(e, "email")} />
                <div className='floating-label'>
                  Adresse email
                </div>
              </div>
              <div className='error-message'>
                {
                  !validation.email ? formFields.email.trim().length ? "Veuillez saisir une adresse mail valide" : "Champ requis" : ""
                }
              </div>
            </Col>
            <Col className='mbc-20' xs={24}>
              <div className={clsx('input-contact input-phone ', { "error-input": !validation.phone_number })} id='phone_number'>
                <Input name="phone_number" value={formattedPhoneNumber(formFields.phone_number)} addonBefore={selectPhoneCode} onChange={handleChangeInput} onFocus={() => handleFocusInput("phone_number")} onBlur={(e) => handleBlurInput(e, "phone_number")} />
                <div className='floating-label'>
                  Téléphone
                </div>

              </div>
              <div className='error-message'>
                {
                  !validation.phone_number ? formFields.phone_number.length ? "Veuillez saisir un numéro valide" : "Champ requis" : ""
                }
              </div>
            </Col>
            {visibleFieldsCategoryReclamation}
            {visibleFieldsSubCategoryReclamation}
            {visibleFieldsOptionsCategoryReclamation}
            {visibleFieldsOptionsSubCategoryReclamation}
            <Col className='mbc-20' xs={24}>
              <div className={clsx('input-contact input-phone textarea-field custom-textarea-wrapper', { "error-input": validation && validation.message === false })} id='message'>
                <TextArea rows={4} name="message" value={formFields.message} onChange={handleChangeInput} className='custom-input' onFocus={() => handleFocusInput("message")} onBlur={(e) => handleBlurInput(e, "message")} />
                <div className='floating-label-message'>
                  Message
                </div>

              </div>
              <div className='error-message'>
                {
                  validation && validation.message === false ? "Champ requis" : ""
                }
              </div>
            </Col>
            <Col xs={24}>
              <Button type='primary' loading={loader} onClick={onSubmit} className='custom-button green-button w-100 custom-font d-flex align-items-center justify-content-center'>
                Envoyer
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <FooterFront />
    </>
  );

}

export default Contact