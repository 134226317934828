import { GET_APPLICATION_HISTORY, GET_APPLICATION_HISTORY_SUCCESS } from "redux/constants/ApplicationHistory";

export const getApplicationHistory = (filter) => {
    return {
        type: GET_APPLICATION_HISTORY,
        filter
    };
};

export const getApplicationHistorySuccess = (application) => {
    return {
        type: GET_APPLICATION_HISTORY_SUCCESS,
        payload: application
    }
};
