
import { GET_APPLICATION_HISTORY, GET_APPLICATION_HISTORY_SUCCESS } from "redux/constants/ApplicationHistory";

const initState = {
    loading: false,
    applicationHistory: null,
    count: 0
}

const applicationHistory = (state = initState, action) => {
    switch (action.type) {
        case GET_APPLICATION_HISTORY:
            return {
                ...state,
                loading: true
            }
        case GET_APPLICATION_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                applicationHistory: action.payload?.data,
                count: action.payload?.meta?.filter_count
            }
        default:
            return state;
    }
}

export default applicationHistory