
import {
   GET_APPLICATIONS,
    GET_APPLICATIONS_SUCCESS ,
   GET_APPLICATIONS_BY_ID,
   GET_APPLICATIONS_BY_ID_SUCCESS,
   GET_APPLICATIONS_FIELDS,
   GET_APPLICATIONS_FIELDS_SUCESS,
   GET_APPLICATION_STEPS_SUCESS
} from '../constants/Applications';

const initState = {
    loading: false,
    loadingFields: false,
    applications: null,
    count: null,
    currentApplication: null,
    fields:null,
    applicationSteps: null
}



const applications = (state = initState, action) => {
    switch (action.type) {
        case GET_APPLICATIONS:
            return {
                ...state,
                loading: true
            }
        case GET_APPLICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                applications: action?.applications?.data,
                count: action?.applications?.meta?.filter_count
            }
        case GET_APPLICATIONS_BY_ID : 
        return {
            ...state,
            loading: true
        }
        case GET_APPLICATIONS_BY_ID_SUCCESS : 
        return {
            ...state,
            loading: false,
            currentApplication: action.currentApplication
        }
        case GET_APPLICATIONS_FIELDS : 
        return {
            ...state,
            loadingFields: true
        }
        case GET_APPLICATIONS_FIELDS_SUCESS : 
        return {
            ...state,
            loadingFields: false,
            fields: action.fields
        }
        case GET_APPLICATION_STEPS_SUCESS : 
        return {
            ...state,
            applicationSteps: action.list
        }
        default:
            return state;
    }
}

export default applications