import {
  DashboardOutlined,
  CalendarOutlined,
  HeatMapOutlined,
  SolutionOutlined,
  UserOutlined,
  NotificationOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import { SUPER_ADMIN, CHEF_ZONE, RESPONSABLE_JURIDIQUE, RESPONSABLE_QUALITE, RESPONSABLE_RESEAU, RESPONSABLE_RESEAU_BO, ARCHITECTE, DIRECTEUR_ACHAT, DIRECTEUR_DEV_RESEAU, DIRECTEUR_COMMERCIAL, CANDIDAT, CHEF_PRODUIT_MARKETING, DIRECTEUR_GENERAL, RESPONSABLE_TRADE, DIRECTEUR_MARKETING } from 'constants/RolesConstant'
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const AppNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/dashboard`,
    originalPath: `${APP_PREFIX_PATH}/dashboard`,
    title: 'Tableau de bord',
    icon: DashboardOutlined,
    breadcrumb: false,
    componentPath: './dashboard/index.js',
    roles: [SUPER_ADMIN, DIRECTEUR_DEV_RESEAU, DIRECTEUR_GENERAL, DIRECTEUR_MARKETING],
    submenu: [],
    showInMenu: true,
    index: 1
  },
  {
    key: 'administrateur',
    path: `${APP_PREFIX_PATH}/admins`,
    originalPath: `${APP_PREFIX_PATH}/admins`,
    title: 'Administrateurs',
    icon: UserOutlined,
    breadcrumb: false,
    componentPath: './admins',
    roles: [SUPER_ADMIN, DIRECTEUR_DEV_RESEAU],
    submenu: [],
    showInMenu: true,
    index: 2
  },
  {
    key: 'administrateur-update-create',
    path: `${APP_PREFIX_PATH}/admins/:id`,
    originalPath: `${APP_PREFIX_PATH}/admins`,
    title: 'Administrateurs',
    icon: UserOutlined,
    breadcrumb: false,
    componentPath: './admins/UpdateUser',
    roles: [SUPER_ADMIN, DIRECTEUR_DEV_RESEAU, DIRECTEUR_MARKETING],
    submenu: [],
    showInMenu: false,
  },
  {
    key: 'gerances',
    path: `${APP_PREFIX_PATH}/applications/:type`,
    originalPath: `${APP_PREFIX_PATH}/applications/gerances`,
    title: 'Gérances libres',
    icon: NotificationOutlined,
    roles: [SUPER_ADMIN, DIRECTEUR_GENERAL, DIRECTEUR_MARKETING, RESPONSABLE_TRADE, CHEF_PRODUIT_MARKETING, CANDIDAT, CHEF_ZONE, RESPONSABLE_JURIDIQUE, RESPONSABLE_QUALITE, RESPONSABLE_RESEAU, RESPONSABLE_RESEAU_BO, ARCHITECTE, DIRECTEUR_ACHAT, DIRECTEUR_DEV_RESEAU, DIRECTEUR_COMMERCIAL],
    breadcrumb: false,
    componentPath: './applications',
    submenu: [],
    showInMenu: true,
    initalRoute: "gerances",
    index: 4
  },
  {
    key: 'franchises',
    path: `${APP_PREFIX_PATH}/applications/:type`,
    originalPath: `${APP_PREFIX_PATH}/applications/franchises`,
    title: 'Franchises',
    icon: SolutionOutlined,
    roles: [SUPER_ADMIN, DIRECTEUR_GENERAL, DIRECTEUR_MARKETING, CHEF_ZONE, RESPONSABLE_TRADE, CHEF_PRODUIT_MARKETING, CANDIDAT, RESPONSABLE_JURIDIQUE, RESPONSABLE_QUALITE, RESPONSABLE_RESEAU, RESPONSABLE_RESEAU_BO, DIRECTEUR_ACHAT, DIRECTEUR_DEV_RESEAU, DIRECTEUR_COMMERCIAL],
    breadcrumb: false,
    componentPath: './applictions',
    submenu: [],
    showInMenu: true,
    initalRoute: "franchises",
    index: 5
  },
  {
    key: 'application-update-create',
    path: `${APP_PREFIX_PATH}/applications/:type/:id`,
    originalPath: `${APP_PREFIX_PATH}/applications`,
    componentPath: './applications/UpdateApplication',
    roles: [SUPER_ADMIN, CHEF_ZONE, DIRECTEUR_MARKETING, CHEF_PRODUIT_MARKETING, RESPONSABLE_TRADE, DIRECTEUR_GENERAL, CANDIDAT, RESPONSABLE_JURIDIQUE, CHEF_PRODUIT_MARKETING, RESPONSABLE_QUALITE, RESPONSABLE_RESEAU, RESPONSABLE_RESEAU_BO, ARCHITECTE, DIRECTEUR_ACHAT, DIRECTEUR_DEV_RESEAU, DIRECTEUR_COMMERCIAL],
    submenu: [],
    showInMenu: false,
  },
  {
    key: 'reclamations',
    path: `${APP_PREFIX_PATH}/reclamations`,
    originalPath: `${APP_PREFIX_PATH}/reclamations`,
    title: 'Réclamations',
    icon: InfoCircleOutlined,
    roles: [SUPER_ADMIN,DIRECTEUR_MARKETING, DIRECTEUR_GENERAL, DIRECTEUR_DEV_RESEAU, DIRECTEUR_COMMERCIAL],
    breadcrumb: false,
    componentPath: './reclamations',
    submenu: [],
    showInMenu: true,
    index: 6
  },
  {
    key: 'calendrier',
    path: `${APP_PREFIX_PATH}/calender`,
    originalPath: `${APP_PREFIX_PATH}/calender`,
    title: 'Calendrier',
    icon: CalendarOutlined,
    roles: [SUPER_ADMIN, DIRECTEUR_GENERAL, DIRECTEUR_MARKETING, CHEF_PRODUIT_MARKETING, RESPONSABLE_TRADE, CHEF_ZONE, RESPONSABLE_QUALITE, RESPONSABLE_RESEAU, RESPONSABLE_RESEAU_BO, DIRECTEUR_ACHAT, ARCHITECTE, DIRECTEUR_DEV_RESEAU, DIRECTEUR_COMMERCIAL],
    breadcrumb: false,
    componentPath: './calendar',
    submenu: [],
    showInMenu: true,
    index: 6
  },
  {
    key: 'heat-map',
    path: `${APP_PREFIX_PATH}/heat-map`,
    originalPath: `${APP_PREFIX_PATH}/heat-map`,
    title: 'Heat Map',
    icon: HeatMapOutlined,
    roles: [SUPER_ADMIN, CHEF_ZONE, DIRECTEUR_MARKETING, RESPONSABLE_JURIDIQUE, RESPONSABLE_QUALITE, RESPONSABLE_RESEAU, RESPONSABLE_RESEAU_BO, ARCHITECTE, DIRECTEUR_ACHAT, DIRECTEUR_DEV_RESEAU, DIRECTEUR_COMMERCIAL, CHEF_PRODUIT_MARKETING, DIRECTEUR_GENERAL, RESPONSABLE_TRADE],
    breadcrumb: false,
    componentPath: './heatMap',
    submenu: [],
    showInMenu: true,
    index: 7
  },
  {
    key: 'settings',
    path: `${APP_PREFIX_PATH}/settings/:page`,
    originalPath: `${APP_PREFIX_PATH}/settings/change-password`,
    title: 'Paramètres',
    icon: SettingOutlined,
    roles: [SUPER_ADMIN, DIRECTEUR_GENERAL, DIRECTEUR_MARKETING, CHEF_PRODUIT_MARKETING, RESPONSABLE_TRADE, CHEF_ZONE, CANDIDAT, RESPONSABLE_JURIDIQUE, RESPONSABLE_QUALITE, RESPONSABLE_RESEAU, RESPONSABLE_RESEAU_BO, ARCHITECTE, DIRECTEUR_ACHAT, DIRECTEUR_DEV_RESEAU, DIRECTEUR_COMMERCIAL],
    breadcrumb: false,
    componentPath: './settings',
    initalRoute: "change-password",
    submenu: [],
    showInMenu: true,
    index: 8
  },

]

const navigationConfig = [
  ...AppNavTree
]

export default navigationConfig;
