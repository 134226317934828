import { GET_APPLICATIONS, GET_APPLICATIONS_SUCCESS, GET_APPLICATIONS_BY_ID, GET_APPLICATIONS_BY_ID_SUCCESS, GET_APPLICATIONS_FIELDS, GET_APPLICATIONS_FIELDS_SUCESS,GET_APPLICATION_STEPS, GET_APPLICATION_STEPS_SUCESS } from "redux/constants/Applications"

export const getApplications = (page, filter) => {
    return {
        type: GET_APPLICATIONS,
        page, 
        filter
    }
}
export const getApplicationsSuccess = (applications) => {
    return {
        type: GET_APPLICATIONS_SUCCESS,
        applications
    }
}
export const getApplicationById = (id) => {
    return {
        type: GET_APPLICATIONS_BY_ID,
        id
    }
}
export const getApplicationByIdSuccess = (currentApplication) => {
    return {
        type: GET_APPLICATIONS_BY_ID_SUCCESS,
        currentApplication
    }
}
export const getApplicationsFields = () => {
    return {
        type: GET_APPLICATIONS_FIELDS,
    }
}
export const getApplicationsFiedlsSuccess = (fields) => {
    return {
        type: GET_APPLICATIONS_FIELDS_SUCESS,
        fields
    }
}
export const getApplicationSteps = () => {
    return {
        type: GET_APPLICATION_STEPS,
    }
}
export const getApplicationStepsSuccess = (list) => {
    return {
        type: GET_APPLICATION_STEPS_SUCESS,
        list
    }
}